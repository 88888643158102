import style from './style.module.scss';

export default (props) => {
  return (
    <div className={style['banner']}>
      <p>
        Asset Register v3 will be <span>deprecated</span> soon, and we're
        transitioning to <strong>v4</strong>, a completely redesigned
        application aimed at modernizing our system and processes. If you have
        modification rights on the current version, please notify us{' '}
        <a href="https://ecgit.freshservice.com/a/tickets/new" target="_blank">
          here
        </a>{' '}
        so we can facilitate the transfer of your role. Thank you for your
        cooperation.
      </p>
    </div>
  );
};
